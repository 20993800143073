// ** React Imports
import { Suspense, lazy } from "react"
import ReactDOM from "react-dom"

// ** Redux Imports
import { Provider } from "react-redux"
import { store } from "./redux/store"

// ** Intl & ThemeColors Context
import { ToastContainer } from "react-toastify"
import { ThemeContext } from "./utility/context/ThemeColors"

// ** Spinner (Splash Screen)
import Spinner from "./Components/SpinnerTributei"

// ** Ripple Button
import "./@core/components/ripple-button"

// ** PrismJS
import "prismjs"
import "prismjs/themes/prism-tomorrow.css"
import "prismjs/components/prism-jsx.min"

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css"

// ** React Toastify
import "@styles/react/libs/toastify/toastify.scss"

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css"
import "./@core/scss/core.scss"
import "./assets/scss/style.scss"
import 'moment/locale/pt-br'
import { Portuguese } from "flatpickr/dist/l10n/pt"
import flatpickr from 'flatpickr'
import moment from 'moment'
flatpickr.localize(Portuguese)
moment.locale('pt-br')
// ** Service Worker
import * as serviceWorker from "./serviceWorker"
import { EmpresasProvider } from "./contexts/EmpresasContext"
import { RefreshTokenProvider } from "./contexts/Refreshtoken"
import { GuiasContextProvider } from "./contexts/GuiasContext"
import { SimuladorProvider } from "./contexts/SimuladorContext"
import { RegrasProvider } from "./contexts/RegrasContext"
import { UtilsProvider } from "./contexts/UtilsContext"
import { JornadaProvider } from "./contexts/JornadaContext"
import { CestProvider } from "./contexts/CestContext"
import { IcmsDifalProvider } from "./contexts/IcmsDifalContext"
import api from "./services/api"
import Cookies from 'universal-cookie'
import { handleLoginState } from "./redux/authentication"
// ** Lazy load app
const LazyApp = lazy(() => import("./App"))
function listenCookieChange(callback, interval = 1000) {
  let lastCookie = document.cookie
  setInterval(() => {
    const cookie = document.cookie
    if (cookie) {
      try {
        callback({oldValue: cookie})
      } finally {
        lastCookie = cookie
      }
    }
  }, interval)
}
const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  return item ? JSON.parse(item) : null
}
let algo = true
async function handleRefresh() {
  const cookies = new Cookies()
  const date = new Date()
  date.setTime(date.getTime() + (30 * 60 * 1000))
  console.log("cookie setaado")
  algo = true
  await api.post('/auth/refresh').then((response) => {
    store.dispatch(handleLoginState(response.data))
    api.defaults.headers.common['Authorization'] = `Bearer ${initialUser().access_token}`
  })
  cookies.set("access_token", initialUser().access_token, {path:"/", expires: date})
  }
listenCookieChange(({oldValue, newValue}) => {
  const regex = new RegExp("access_token", "gi")
  if (!regex.test(oldValue)) {
    if (algo && initialUser() !== null) {
      algo = false
      handleRefresh()
    }
  }
}, 1000)
ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <RegrasProvider>
        <EmpresasProvider>
          <IcmsDifalProvider>
            <RefreshTokenProvider>
              <GuiasContextProvider>
                <SimuladorProvider>
                  <CestProvider>
                    <JornadaProvider>
                      <ThemeContext>
                        <UtilsProvider>
                          <LazyApp />
                        </UtilsProvider>
                        <ToastContainer hideProgressBar={true} newestOnTop />
                      </ThemeContext>
                    </JornadaProvider>
                  </CestProvider>
                </SimuladorProvider>
              </GuiasContextProvider>
            </RefreshTokenProvider>
          </IcmsDifalProvider>
        </EmpresasProvider>
      </RegrasProvider>
    </Suspense>
  </Provider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
