import { createContext, useState } from "react"
import { formatCnpjCpf } from "../utility/formatCnpj"

export const EmpresasContext = createContext({})

const item = localStorage.getItem('empresaSelecionada')
export function EmpresasProvider(props) {
  const initialUser = () => {
    const item = window.localStorage.getItem('userData')
    return item ? JSON.parse(item) : null
  }
  
  const empresa = initialUser() === null || (initialUser().user.tipo.toLowerCase() !== "admin" && initialUser().user.jornada === "cadEmpresa") || initialUser().user.tipo.toLowerCase() === "admin" || initialUser().user.clientes.length === 0 ? false : {
      name:`${ formatCnpjCpf(initialUser().user.clientes[0].cnpj.length === 0 || initialUser().user.clientes[0].cnpj === null ? initialUser().user.clientes[0].cpf : initialUser().user.clientes[0].cnpj)}, ${initialUser().user.clientes[0].nome}, ${initialUser().user.clientes[0].estado}`
    }
  const empresaInitial = item === null ? empresa : JSON.parse(item)
  const [currentEmpresa, setCurrentEmpresa] = useState(empresaInitial)
  const estado = currentEmpresa === false ? false : currentEmpresa.name.split(",").pop().trim().toLowerCase()
  const cnpjWithoutMask = currentEmpresa === false ? false : currentEmpresa.name.split(",")[0].replace(/[^\d]+/g, "")
  const [idEmpresa, setEmpresa] = useState('') 
  function handleEmpresa(value) {
    setCurrentEmpresa(value)
    localStorage.setItem('empresaSelecionada', JSON.stringify(value))
  }

  function handleId(value) {
    setEmpresa(value)
  }
  return (
    <EmpresasContext.Provider value={{ currentEmpresa, handleEmpresa, handleId, idEmpresa, cnpjWithoutMask, estado}} >
      {props.children}
    </EmpresasContext.Provider>
  )
}